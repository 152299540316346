import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Container from './components/Container/Container'
import TestPage from './components/TestPage/TestPage'

declare global {
  interface Window {
    AndroidFunction: any
    webkit: any
    getBookFromNative: any
  }
}

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Container />}></Route>
          <Route path="/test" element={<TestPage />}></Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
