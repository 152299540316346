import { type Book, BookReader } from '@boosters/book-reader'
import { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const TestPage: FC = () => {
  const [book, setBook] = useState<Book>()
  const query = useQuery()
  const navigate = useNavigate()

  const bookLink = query.get('book')

  useEffect(() => {
    if (bookLink) {
      fetch(atob(bookLink))
        .then((res) => res.json())
        .then((data) => {
          setBook(data)
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Book Request Error. Please check book query parameter and try again.')
        })
    }
  }, [bookLink])

  const handleCloseClick = useCallback(() => {
    navigate('/')
  }, [])

  return <BookReader book={book} onClose={handleCloseClick} />
}

export default TestPage
