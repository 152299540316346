import { type FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './container.module.scss'

const Container: FC = () => {
  const navigate = useNavigate()

  const [link, setLink] = useState<string>('')

  const handleClick = () => {
    const _link = btoa(link)
    navigate(`/test?book=${_link}`)
  }

  return (
    <div className={styles.container}>
      <h6 className={styles.title}>Paste the link to the json file in the input</h6>
      <form action="" className={styles.form}>
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className={styles.input}
        />
        <button className={styles.button} onClick={handleClick}>
          Submit
        </button>
      </form>
    </div>
  )
}

export default Container
